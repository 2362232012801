import React from 'react';
import logo from './logo.svg';
import axios from 'axios'
import Quiz from 'react-quiz-component';
import './App.css';

function App() {
  const [quizState, setQuiz] = React.useState(0);
  const [bank, setBank] = React.useState("mixed");
  const [clocked,setClocked] = React.useState(true)
  const translate1 = {
    "F1": "Section 1",
    "F2": "Section 2",
    "F3": "Section 3",
    "F4": "Section 4",
    "F5": "Section 5",
    "F6": "Section 6",
    "F7": "Section 7",
    "F8": "Section 8"
  }

  const translate2 = {
    "F1": "Licensing conditions and station identification",
    "F2": "Technical aspects",
    "F3": "Transmitters and receivers",
    "F4": "Feeders and antennas",
    "F5": "Propagation",
    "F6": "Electro magnetic compatibility (EMC)",
    "F7": "Operating practices and procedures",
    "F8": "Section 8 – Safety"
  }
  function SelectCallback(e) {
    setClocked(false)
    console.log("change")
    if (bank === "mixed") {
      var finalQs = []
      function add_q(v) {
        finalQs = finalQs.concat(v)
        console.log(finalQs)
        if (finalQs.length >= 24) {
          const quiz = {
            quizTitle: "Mixed Quiz",
            quizSynopsis: "suff",
            questions: finalQs
          }
          setQuiz(quiz)
        }
      }
      for (var i = 1; i < 9; ++i){
        axios.get("https://4r03cqvq60.execute-api.eu-west-2.amazonaws.com/dev/bank/F" + i.toString(), {headers:{"x-api-key":" G89ddp4GHT5suIHz6vEHS49NrsRPlULJ9GWqi2X1"}})
        .then(response => {
          console.log(response)
          axios.post("https://4r03cqvq60.execute-api.eu-west-2.amazonaws.com/dev/render", response.data, {headers:{"x-api-key":" G89ddp4GHT5suIHz6vEHS49NrsRPlULJ9GWqi2X1"}})
          .then(response => {
            var questions = [];

            response.data.Items.forEach(x => {
              console.log(x.ImageFilename)
              var q = {
                question: x.Question,
                questionType: "text",
                answers: x.Answers,
                correctAnswer: x.CorrectAnswer + 1,
                messageForCorrectAnswer: "Correct answer. Good job.",
                answerSelectionType: "single",
                messageForIncorrectAnswer: "Incorrect answer. Please try again.",
                explanation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                point: "1"
              }
              if (x.ImageFilename !== ""){
                q.questionPic = "/resources/" + x.ImageFilename
              }
              questions.push(q)
            });

            var random_questions = []
            for (var i = 0; i < 3; i++) {
              random_questions.push(questions.splice(Math.floor(Math.random() * questions.length), 1)[0])
            }
            add_q(random_questions)
            console.log(response)
          });
      });
    }
  }
    axios.get("https://4r03cqvq60.execute-api.eu-west-2.amazonaws.com/dev/bank/" + bank, {headers:{"x-api-key":" G89ddp4GHT5suIHz6vEHS49NrsRPlULJ9GWqi2X1"}})
    .then(response => {
      console.log(response)
      axios.post("https://4r03cqvq60.execute-api.eu-west-2.amazonaws.com/dev/render", response.data, {headers:{"x-api-key":" G89ddp4GHT5suIHz6vEHS49NrsRPlULJ9GWqi2X1"}})
      .then(response => {
        var questions = [];
        response.data.Items.forEach(x => {

          var q = {
            question: x.Question,
            questionType: "text",
            answers: x.Answers,
            correctAnswer: x.CorrectAnswer + 1,
            messageForCorrectAnswer: "Correct answer. Good job.",
            answerSelectionType: "single",
            messageForIncorrectAnswer: "Incorrect answer. Please try again.",
            explanation: "Coming Soon",
            point: "1"
          }
          if (x.ImageFilename !== ""){
            q.questionPic = "/resources/" + x.ImageFilename
          }
          questions.push(q)
        });

        var random_questions = []
        for (var i = 0; i < 5; i++) {
          random_questions.push(questions.splice(Math.floor(Math.random() * questions.length), 1)[0])
        }
        const quiz = {
          quizTitle: translate1[bank],
          quizSynopsis: translate2[bank],
          questions: random_questions
        }
        setQuiz(quiz)
        console.log(response)
      });
    });
  }

  return (
    <div className="App">
    {clocked ?
      <div>
        <select value={bank} onChange={(e) => setBank(e.target.value)}>
          <option value="F1"> Section 1 </option>
          <option value="F2"> Section 2 </option>
          <option value="F3"> Section 3 </option>
          <option value="F4"> Section 4 </option>
          <option value="F5"> Section 5 </option>
          <option value="F6"> Section 6 </option>
          <option value="F7"> Section 7 </option>
          <option value="F8"> Section 8 </option>
          <option value="mixed"> Mixed x 24 </option>
        </select>
        <button onClick={SelectCallback}> Launch quiz </button>
        </div>
      :
        <Quiz quiz={quizState} shuffle={true} showInstantFeedback={false}/>
      }
    </div>
  );
}

export default App;
